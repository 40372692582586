export default {
  report: "รายงาน",
  weeklyReport: "รายงานสัปดาห์",
  monthlyReport: "รายงานเดือน",
  reportByHour: "รายงานตามชั่วโมง",
  reportByDay: "รายงานตามวัน",
  filters: "กรอง",
  ratingBySelectedDate: "การให้คะแนนตามวันที่เลือก",
  ratingStar: "ให้คะแนนดาว",
  total: "รวม",
  average: "เฉลี่ย",
  hour: "ชั่วโมง",
  totalBooking: "การจองทั้งหมด",
  totalOrder: "รวมออเดอร์ทั้งหมด",
  totalAdult: "ผู้ใหญ่",
  totalKids: "เด็ก",
  totalSeats: "สถานะจำนวนที่นั่ง",
  availableSeats: "สถานะจำนวนที่นั่ง",
  totalNumberOfDineInAndPeople: "จำนวนลูกค้าทั้งหมด",
  partySize: "จำนวนที่จอง",
  noOfPeopleOrNoOfBooking: "ลูกค้าทั้งหมด / การจองทั้งหมด",
  packageReportByTimeOfDay:
    "รายงานแพ็กเกจ [ทานที่ร้าน / เดลิเวอรี่] ตามช่วงเวลาของวัน",
  grandTotal: "รวมทั้งหมด",
  serviceTypeReportByTimeOfDay:
    "รายงานแพ็กเกจ [ทานที่ร้าน / เดลิเวอรี่] ตามช่วงเวลาของวัน",
  dineIn: "รับประทานอาหารในร้านอาหาร",
  delivery: "เดลิเวอรี่",
  selfPickup: "รับเอง",
  soldOutReport: "รายงานการขายหมดแล้ว",
  package: "แพ็คเกจ",
  mon: "จ",
  tue: "อ",
  wed: "พ",
  thu: "พฤ",
  fri: "ศ",
  sat: "ส",
  sun: "อา",
  dineInByDay: "รับประทานอาหารในร้านอาหาร",
  date: "วันที่",
  arrived: "มาถึง",
  pendingUpcomingReservations: "รอการมาถึง / การจองโต๊ะที่ใกล้เข้ามา",
  noShow: "ไม่แสดง",
  cancelled: "ยกเลิก",
  rejected: "ปฏิเสธการจอง",
  reports: "รายงาน",
  previous: "ก่อนหน้า",
  next: "ถัดไป",
  lunch: "มื้อกลางวัน (12.00 - 16.00 น.)",
  dinner: "มื้อเย็น (16.00 - 23.45 น.)",
  allTimeslotAvailable: "ทุกช่วงเวลาว่าง",
  someTimeslotUnavailable: "บางช่วงเวลาไม่ว่าง",
  allTimeslotUnavailable: "ทุกช่วงเวลาไม่ว่าง",
  branchCoversByTimeOfDay: "Branch Covers by Time of Day",
  covers: "คน",
  restaurant: "ร้านอาหาร",
};

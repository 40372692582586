export default {
  exportHistory: "导出历史",
  reportName: "报告名称",
  booking: "預訂",
  voucher: "優惠券",
  package: "套餐",
  billing: "账单",
  analytic: "分析",
  onQueue: "排队中",
  inProgress: "进行中",
  done: "完成",
  reportID: "报告编号",
  reportType: "报告类型",
  status: "状态",
  createdAt: "创建于",
  finishedAt: "完成时间",
  startDate: "开始日期",
  endDate: "结束日期",
  action: "操作",
  download: "下载",
};

export const button = {
  cancel: "取消",
  save: "儲存",
  update: "更新",
  register: "Register",
  goToLogin: "Go to Log in",
  goToPartnerPortal: "Go to Partner Portal",
  buttonEdit: "编辑",
  buttonMenu: "Menu",
  buttonDelete: "删除",
  confirm: "确认",
  export: "匯出",
  filters: "篩選",
  submit: "提交",
  clearAll: "清除所有",
  showResults: "显示结果",
  create: "创建",
  send: "Send",
  close: "Close",
  search: "Search",
  viewMenu: "查看菜单",
  printMenu: "打印菜单",
};

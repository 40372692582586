export default {
  accountManagement: "账户管理",
  profile: "个人资料",
  store: "商店",
  userAccessRight: "用户与访问权限",
  status: "狀態",
  generalInformation: "基本信息",
  firstName: "名字",
  lastName: "姓氏",
  email: "电子邮件",
  role: "角色",
  contactNumber: "联系电话",
  changePassword: "更改密码",
  oldPassword: "旧密码",
  newPassword: "新密码",
  cancel: "取消",
  update: "更新",
  phone: "电话号码",
  userId: "用户ID",
  name: "姓名",
  createdAt: "創建於",
  lastOnline: "最后在线",
  action: "操作",
  owner: "所有者",
  contributor: "Contributor",
  viewer: "Viewer",
  lastSeen: "最后在线",
  joined: "加入时间",
  createANewUser: "创建新用户",
  password: "密碼",
  confirmPassword: "确认密码",
  undo: "撤销",
  createNewUser: "创建新用户",
  create: "创建",
  export: "Export",
  resetPassword: "重置密码",
  receiveLinkResetPassword:
    "User will receive a link to reset password via email.",
  editAccessRight: "Do you want to edit access right",
  confirmDeleteAccount: "您确定要删除账户吗",
  editUser: "編輯用戶",
  confirmDeleteImage: "Are you sure you want to delete this image?",
  giveAccessToHungryhubTeam: "授予Hungryhub团队访问权限",
  accessWillBeGiven: "访问权限将授予 HungryHub 团队, 持续至接下来的24小时",
  accessWasGiven: "Access was given to hungryhub team until next 24 hours",
  close: "關閉",
  confirm: "確認",
};

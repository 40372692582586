export default {
  redeem: "兑换",
  QRCheckIn: "二维码签到",
  createvoucher: "創建優惠券",
  deletevoucher: "Delete All vouchers",
  createAVoucher: "創建優惠券",
  voucherList: "优惠券列表",
  editVoucher: "编辑优惠券",
  tableHeader: {
    voucherId: "优惠券编号",
    voucherName: "优惠券名称",
    price: "价格",
    quantity: "数量",
    qtySold: "已售数量",
    qtyRedeemed: "已兑换数量",
    validityPeriod: "有效期限",
    action: "操作",
  },
  ///voucher redemption history
  voucherRedemtionHistory: "優惠券兌換歷史",
  search: "搜索",
  tableHeaderHistory: {
    voucherNumber: "优惠券编号",
    transactionId: "交易编号",
    voucherDetails: "优惠券详情",
    guestDetails: "顾客详情",
    redeemedDate: "兑换日期/时间",
    redeemedTime: "兑换时间",
  },
  basicInformation: "Basic Information",
  voucherNameEN: "优惠券名称 (EN)",
  voucherNameTH: "优惠券名称 (TH)",
  descriptionEN: "描述 (EN)",
  descriptionTH: "描述 (TH)",
  packageType: "套餐类型",
  learnMore: "了解更多",
  sellingPeriod: "销售期限",
  validityPeriod: "有效期限",
  termsConditions: "条款和条件 (PDF)",
  pasteALink: "Paste a link URL or choose file to upload",
  quantityPricing: "数量与定价",
  currency: "货币",
  originalPrice: "原价",
  perVoucher: "每张优惠券",
  netPrice: "净价",
  howManyVouchers: "您想要出售多少张优惠券？",
  iWantToSell: "我想出售",
  vouchers: "優惠券",
  iWantToLimit: "我想限制",
  perOrder: "每单（可选）",
  acceptedPaymentMethods: "接受的付款方式",
  acceptAll: "全部接受",
  paymentInfo: "付款信息",
  debitCreditCard: "借记卡/信用卡",
  promptPayQR: "PromptPay 二维码",
  voucherPrice: "优惠券价格",
  quantityOfVoucher: "优惠券数量",
  exportVoucherList: "导出优惠券列表",
  exportVoucherHistory: "导出优惠券历史",
  voucherRedemptionHistory: "優惠券兌換歷史",
  durationDate: "持续日期",
  voucherNumber: "優惠券號碼",
  editGuestDetails: "编辑顾客详细信息",
  guestDetails: "顾客详细信息（可选)",
  Editguestdetails: "Edit guest details",
  addGuestDetails: "添加顾客详细信息",
  voucherSellingPriod: "This voucher is in the selling period!",
  wouldYouLikeDeleteVoucher:
    "Would you like to go ahead and delete this voucher anyway ?",
  areyouSuredeleteVoucher: "您確定要刪除此優惠券嗎?",
  support: "Support",
  status: "status",
  voucherisapartof: "This voucher is a part of",
  transactionID: "transaction ID",
  voucherDetails: "Voucher Details",
  guestDetailsOptional: "guest details (Optional)",
  confirmRedemption: "Confirm redemption",
  redeemedDate: "兑换日期/时间",
  selectDate: "Select Date",
  noDataAvailable: "暂无数据",
  voucherRedemption: "Voucher Redemption",
  redeemedOn: "Redeemed on",
  at: "at",
};

export default {
  billing: "Billing",
  billingOverview: "billing overview",
  totalDueAmountExcludingTax: "Total due amount (excluding tax)",
  totalGMV: "Total GMV",
  totalRevenue: "Total revenue",
  totalRevenueGMV: "Total revenue (GMV)",
  totalPrepaymentAndCodes: "Total prepayment + codes",
  commissionBreakdown: "Commission breakdown",
  packages: "Packages",
  hungryHome: "Hungry@home",
  allYouCanEat: "All You Can Eat",
  packageName: "Package Name",
  price: "Price",
  totalCover: "Total Cover",
  commission: "Commission ",
  experience: "Experience",
  partyPack: "Party Pack",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
};

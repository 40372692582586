export default {
  inputPlaceholder: "Package Name",
  createPackage: "Create Package",
  createAPackage: "Create a package",
  deletePackage: "Delete All Packages",
  packageList: "Package List",
  packageStatus: "Package Status",
  searchFilters: "Search Filters",
  createdDate: "Created Date",
  rangeDate: "Range Date",
  singleDate: "Single Date",
  updatedDate: "Updated at",
  startDate: "Start Date",
  selectDate: "Select Date",
  emailAddress: "Email Address",
  reportType: "Report Type",
  exportPackageList: "Export Package List",
  support: "Support",
  noResult: "No Result",
  gotoAllotment: "Go to Allotment Management",
  tableHeader: {
    packageId: "Package ID",
    packageName: "Package Name",
    packageType: "Package Type",
    price: "Price",
    bookingDuration: "Booking Duration",
    createdAt: "Created at",
    updateAt: "Updated at",
    action: "Action",
  },
  availableMethods: "Available H@H methods",
  charactersLimit_20: "20 characters limit",
  copyOfBankbook: "A copy of Bankbook account",
  copyOfIdentification: "A copy of Identification card",
  signedEContract: "A signed E - contract(Please download here)",
  acceptAll: "Accept All",
  acceptedPaymentMethods: "Accepted payment methods",
  accordingToCompany:
    "According to Company Certificate(DBD), the authorizer is required to sign “copyright” and stamp company’s seal on copy of documents. The power of attorney document is required if there is right transfer for others apart from committee to sign.",
  add: "Add",
  addMenuItems: "Add menu items",
  addMoreMenuItems: "Add more menu items",
  addMoreOption: "Add more option",
  addMoreSection: "Add more section",
  addPhoto: "Add Photo",
  addSubMenu: "Add sub menu",
  allDay: "All Day",
  allotmentManagement: "Allotment Management",
  applyToAllDay: "Apply to All Day",
  availableRooms: "Available Rooms",
  bookableScheduleAndPeriod: "Bookable schedule and period",
  bookingDuration: "Booking Duration",
  bookingDurationInMinute: "Booking Duration (in minutes)",
  bookingInfo: "Booking Info",
  btsRoute: "BTS Route",
  cancel: "Cancel",
  charactersLimit: "characters limit",
  chargePolicyEn: "Charge Policy (EN)",
  chargePolicyTh: "Charge Policy (TH)",
  chooseFile: "Choose file",
  checkInTime: "Check in time",
  checkOutTime: "Check out time",
  city: "City",
  close: "Close",
  companyCertificate: "Company Certificate(DBD) Valid for 90 days",
  corporation: "Corporation",
  country: "Country",
  coverCmage: "Cover image",
  cuisine: "Cuisine",
  currency: "Currency",
  createMenuList: "Create Menu List",
  closed: "Closed",
  editMenuList: "Edit Menu List",
  dayTimeToReceiveBookings: "Day & Time to receive bookings",
  debitCreditCard: "Debit / Credit Card",
  delete: "Delete",
  description: "Description",
  descriptionEn: "Description (EN)",
  descriptiontH: "Description (TH)",
  designMenulist: "Design menu list",
  district: "District",
  doYouAlsoProvideFood: "Do you also provide food ? ",
  document: "Document",
  deliveryFee: "Delivery fee",
  delivery: "Delivery",
  editInfoAndResubmit: "Edit info and resubmit",
  editSubMenu: "Edit sub menu",
  endDate: "End Date",
  everyday: "Everyday",
  facilities: "Facilities",
  firstBookableTime: "First Bookable Time",
  fixedAmount: "Fixed amount",
  friday: "Friday",
  galleryManangement: "gallery Manangement",
  here: "here",
  hotelBrand: "Hotel Brand",
  howManyVouchers: "How many vouchers would you like to sell ?",
  iWantToLimit: " I want to limit",
  iWantToSell: "I want to sell",
  importFile: "Import File",
  itemNo: "Item No",
  kidPricePolicyEN: "Kid Price Policy (EN)",
  kidPricePolicyTH: "Kid Price Policy (TH)",
  kidsPriceOptional: "Kids Price (optional)",
  kidsPrice: "Kids Price",
  lastBookableTime: "Last Bookable Time",
  learnMore: "Learn More",
  location: "Location",
  locationInGoogleMap: "Location in Google Map",
  logo: "Logo",
  menuImage: "Menu Image",
  img: "img",
  mainContactPerson: "Main contact Person",
  max3Choices: "Max 3 choices",
  maximumNumberofseats: "Maximum number of seats",
  menu: "Menu",
  menuForPackage: "Menu for package",
  menuID: "Menu ID",
  menuSelected: "Menu Selected",
  menuType: "Menu Type",
  minimumAdvanceBookingTime: "Minimum Advance Booking Time",
  minutes: "Minutes",
  mins: "Mins",
  monday: "Monday",
  mrtRoute: "MRT Route",
  name: "Name",
  nameEN: "Name (EN)",
  nameTH: "Name (TH)",
  netPrice: "Net Price",
  noFileChosen: "No file chosen",
  normalChargeUponBooking: "Normal (Charge upon booking)",
  normalSchedule: "Normal Schedule",
  notRequired: "Not Required",
  numberofcourses: "Number of courses",
  numberofdrinks: "Number of drinks",
  numberofguests: "Number of guests",
  numberofadults: "Number of adults",
  onHold: "On hold",
  onGoing: "On-going",
  onSite: "On-site",
  onSiteRestaurants: "On-site Restaurants",
  operatingHours: "Operating  Hours (Open - Close)*",
  originalPrice: "Original Price",
  occursFrom: "Occurs from",
  open: "Open",
  packageMenu: "Package & Menu",
  packageBasicInfo: "Package Basic Info",
  packageNameEN: "Package Name (EN)",
  packageNameTH: "Package Name (TH)",
  packageType: "Package Type",
  pasteALink: "Paste a link URL or choose file to upload",
  paymentInfo: "Payment Info",
  perBooking: "Per Booking",
  perPack: "Per Pack",
  perRoom: "Per Room",
  perOrder: "per order (optional)",
  perPerson: "Per Person",
  percentage: "Percentage",
  periodType: "Period Type",
  phoneNumber: "Phone Number",
  photomanagement: "Photo management",
  pleaseComplete: "Please complete the steps to verify your account",
  pleaseContact: "Please contact Support if you wish to change your Country",
  pleaseExplain: "Please explain about your food service",
  pleaseSelectOne: "Please select one",
  pleaseSpecify: "Please specify the handle or the link",
  pleaseUpload:
    "Please upload documents required for Hungry Hub Partner Account Verification",
  popularZone: "Popular zone",
  preview: "Preview",
  price: "Price",
  pricePerPerson: "Price Per Person",
  priceTiering: "Price tiering (optional)",
  for: "For",
  persons: "Persons",
  pricing: "Pricing",
  primary: "Primary",
  primaryStorePhone: "Primary Store Phone No.",
  promptPayQR: "PromptPay QR",
  quantityPricing: "Quantity & Pricing",
  quantity: "Quantity",
  questionClick: "Question ? Click help for support",
  requirePrePayment: "Require pre - payment",
  restaurantDetailsLocation: "Restaurant details, Location & facilities",
  restaurantDetailsPlace: "Restaurant details, Place & facilities",
  restaurantInformation: "Restaurant information",
  role: "Role",
  saturday: "Saturday",
  save: "Save",
  saveMenuItems: "Save menu items",
  saveSection: "Save section",
  saveSubMenu: "Save sub menu",
  schedule: "Schedule",
  seasonalMenu: "Seasonal Menu",
  seatAvailable: "Seat Available",
  seatingsBookingInfo: "Seatings & Booking Info",
  secondary: "Secondary",
  secondaryStorePhoneNo: "Secondary Store Phone No.",
  sectionName: "Section Name",
  sectionNameEN: "Section Name (EN)",
  sectionNameTH: "Section Name (TH)",
  seeMore: "See More",
  selection: "Selection",
  sellingPeriod: "Selling Period",
  shopeePay: "Shopee Pay",
  shoppingMall: "Shopping Mall",
  shortNameENForSMS: "Short Name (EN) For SMS",
  shortNameTHForSMS: "Short Name (TH) For SMS ",
  socialMediaWebsite: "Social Media & Website",
  soleProprietorship: "Sole Proprietorship",
  specialScheduleOptional: "Special Schedule(optional)",
  starRating: "Star Rating",
  storeSetting: "Store Setting",
  subMenu: "Sub Menu",
  subMenuNameEN: "Sub Menu Name (EN)",
  subMenuNameTH: "Sub Menu Name (TH)",
  sunday: "Sunday",
  specifyRoomType: "Specify room type",
  specialSchedule: "Special Schedule",
  specificPeriod: "Specific period",
  selfPickup: "Self Pickup",
  thai: "Thai",
  thankYouYourAccount: "Thank you! Your account is in review!",
  theCertificate: "The Certificate of value added tax registration (PP20)",
  thursday: "Thursday",
  trueMoneyWallet: "True Money Wallet",
  tuesday: "Tuesday",
  typeOfExperience: "Type of Experience",
  typeOfVenue: "Type of Venue",
  tier: "Tier",
  useExternalEditor: "use external editor",
  untill: "Until",
  validityPeriod: "Validity Period",
  voucherNameEN: "Voucher Name (EN)",
  voucherNameTH: "Voucher Name (TH)",
  vouchers: "vouchers",
  wednesday: "Wednesday",
  youWillHearFromUs: "You will hear from us within 5 - 7 days",
  yourMenuList: "Your Menu List",
  roomTypeNameForThisPackage: "Room type name for this package",
  allYouCanEat: "All You Can Eat (AYCE)",
  partyPack: "Party Pack (PP)",
  xperience: "Xperience (XP)",
  hungryHome: "Hungry@Home (HAH)",
  packageisactive: "This package is active!",
  deletepackageanyway:
    "Would you like to go ahead and delete this package anyway?",
  areYouSureDelete: "Are you sure to delete this package?",
  editAPackageId: "Edit a package ID",
  youHave1Active: "You have at least 1 active package!",
  deleteAllPackage:
    "Would you like to go ahead and delete all packages anyway ?",
  packageAll: "Package: All",
  selectPackageType: "Select package type",
  pleaseEnter: "Please enter a link URL or choose file to upload",
  active: "Active",
  inactive: "Inactive",
  ayce: "All You Can Eat",
  xp: "Xperience",
  pp: "Party Pack",
  hah: "Hungry@Home",
  pack: "Pack",
  person: "Person",
  set: "Set",
};

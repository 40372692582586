export default {
  reviewManangement: "评论管理",
  reviewList: "评论列表",
  reviewCustomer: "审核客户 ",
  textReviewScore: "客户的评分（点击星星评分)",
  comment: "评论",
  buttonCancel: "Cancel",
  buttonSubmit: "Submit",
  bookingID: "預訂號碼",
  customerName: "顧客姓名",
  packageDetails: "套餐詳情",
  rating: "评分",
  photoAndReview: "照片 / 評論",
  occasion: "特别场合",
  recommended: "推薦",
  reviewedAt: "評論時間",
  action: "操作",
  orderStatus: "Order Status:",
  orderLater: "Order Later",
  restaurantRating: "餐厅评分",
  covid19Measures: "新冠防疫措施",
  deliveryRating: "Delivery Rating",
  buttonMenu: "Menu",
  seemore: "查看更多",
  seeless: "收起",
  buttonView: "查看",
  buttonReview: "评论",
  ratingTooltip: {
    overall: "整體",
    food: "食物",
    ambience: "氣氛",
    service: "服務",
    value: "價值",
    socialDistancing: "社交距离",
    overallCleanliness: "整体清洁度",
    staffProtection: "员工防护",
    packaging: "packaging",
    deliveryservice: "Delivery service",
  },
  searchFilters: "搜索筛选",
  filters: "篩選",
  reviewedDate: "评论日期",
  rangeDate: "日期范围",
  singleDate: "单一日期",
  reviewStars: "评分星级",
  packageType: "套餐类型",
  specificPackage: "特定套餐",
  Occasion: "特别场合",
  bookingId: "หมายเลขการจอง",
  startDate: "开始日期",
  selectDate: "Select Date",
  endDate: "结束日期",
};

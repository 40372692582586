export const dashboard = {
  todayBooking: "Today's Bookings",
  upComingBooking: "Upcoming Bookings & Allotments (Next 7 Days)",
  thisMonthBooking: "This Month Bookings",
  thisMonthVoucher: "This Month Voucher Sales",
  pendingBooking: "Pending Bookings",
  bookings: "Bookings",
  covers: "Covers",
  seatsAvailable: "Seats Available",
  revenue: "Revenue",
  redeemed: "Redeemed",
  potentialRevenue: "Potential revenue",
  manageAllotment: "Manage Allotment",
  packagesSummary: "Packages Summary (Past 30 Days)",
  onlinePackages: "Online Packages",
  packages: "Packages",
  bestSeller: "Bestseller",
  hungryHubUnlimitedLobster: "Hungry Hub Unlimited Lobster",
  ratingSummary: "Rating Summary (Past 30 Days)",
  rating: "Rating",
  reviews: "Reviews",
  manageBooking: "Manage Booking",
  overall: "Overall",
  food: "Food",
  ambience: "Ambience",
  service: "Service",
  value: "Value",
  seeMore: "See More",
  today: "Today",
  revenueFromBookings: "Revenue from bookings",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  newsAndUpdate: "News & Update",
};

export default {
  login: "Login",
  titleLogin: "歡迎來到合作夥伴平台",
  userNameField: "電子郵件地址",
  passwordField: "密碼",
  rememberMe: "記住我",
  forgotPassword: "忘記密碼?",
  signin: "登入",
  register: "Register",
  errorMessage:
    "The email and password you entered did not match our records.\nPlease double check and try again.",
  individualLogin: "个人登录",
  groupLogin: "团体登录",
};

export default {
  header: {
    logout: "登出",
    language: "语言",
    searchForm: {
      inputPlaceholder: "预订编号",
      button: "搜索",
    },
    notifications: {
      NewForYou: "全新推荐",
      MarkAllRead: "全部标记为已读",
      PreviousNotifications: "之前的通知",
      updateHeadline: "Update headline",
      youHave: "您有",
      newPendingBooking: "new Pending Bookings",
      newPendingConfirmationBooking: "个新的待确认预订",
      seemore: "查看更多",
      newCustonerReview: "条新的客户评价",
      booking: "预订",
      wroteAReview: "已写评论",
    },
  },
  navigationSidebar: {
    dashboard: "仪表板",
    booking: "预订",
    bookingHistory: "Booking History",
    voucher: "優惠券",
    voucherList: "优惠券列表",
    voucherHistory: "优惠券记录",
    createVoucher: "创建优惠券",
    editVoucher: "编辑优惠券",
    package: "套餐",
    billing: "账单",
    createPackage: "Create Package",
    editPackage: "Edit Package",
    reviewManangement: "评论管理",
    marketing: "市场营销",
    accountManagement: "账户管理",
    profile: "个人资料",
    store: "商店",
    user: "用户与访问权限",
    help: "帮助",
    menu: "Menu",
    menuOverview: "Menu Overview",
    menuItems: "Menu Items",
    createMenuItem: "Create Menu Item",
    editProfile: "编辑个人资料",
    editMenuItem: "Edit Menu Item",
    analytics: "Analytics",
    performanceReview: "Performance Review",
    ranking: "Ranking",
    allotment: "配额管理",
    createAllotment: "Create Allotment",
    editAllotment: "Edit Allotment",
    createBooking: "创建预订",
    editBooking: "编辑预订",
    marketingDetails: "Marketing Details",
    pageNotFound: "Page Not Found",
    exportHistory: "导出历史",
    report: "报告",
  },
  registerLayout: {
    register: "Register",
  },
};

export default {
  report: "报告",
  weeklyReport: "Weekly Report",
  monthlyReport: "Monthly Report",
  reportByHour: "按小时报告",
  reportByDay: "按天报告",
  filters: "筛选器",
  ratingBySelectedDate: "选定日期的评分",
  ratingStar: "评分星级",
  total: "總",
  average: "平均值",
  hour: "小时",
  totalBooking: "总预订",
  totalOrder: "总订单",
  totalAdult: "总成人",
  totalKids: "总儿童",
  totalSeats: "总座位",
  availableSeats: "可用座位",
  totalNumberOfDineInAndPeople: "堂食人数总计",
  partySize: "派对规模",
  noOfPeopleOrNoOfBooking: "人数/预订数",
  packageReportByTimeOfDay: "套餐报告 [堂食 / 外卖] 按时间段",
  grandTotal: "总计",
  serviceTypeReportByTimeOfDay: "服务类型报告 [外卖 / 堂食] 按时间段",
  dineIn: "堂食",
  delivery: "外賣",
  selfPickup: "自取",
  soldOutReport: "售罄报告",
  package: "套餐",
  mon: "周一",
  tue: "周二",
  wed: "周三",
  thu: "周四",
  fri: "周五",
  sat: "周六",
  sun: "周日",
  dineInByDay: "Dine In By Day",
  date: "日期",
  arrived: "Arrived",
  pendingUpcomingReservations: "Pending / Upcoming Reservations",
  noShow: "No Show",
  cancelled: "Cancelled",
  rejected: "已拒绝",
  reports: "报告",
  previous: "上一页",
  next: "下一页",
  lunch: "午餐 (12:00 AM - 4:00 PM)",
  dinner: "晚餐 (4:00 PM - 11:45 PM)",
  allTimeslotAvailable: "所有时间段可用",
  someTimeslotUnavailable: "部分时间段不可用",
  allTimeslotUnavailable: "所有时间段不可用",
  branchCoversByTimeOfDay: "分店覆盖（按时间段)",
  covers: "覆盖率",
  restaurant: "餐廳",
};

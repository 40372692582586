export default {
  export: "匯出",
  reportType: "报告类型",
  excel: "Excel",
  pdf: "PDF",
  filterBy: "筛选条件",
  rangeDate: "日期范围",
  singleDate: "单一日期",
  startDate: "开始日期",
  endDate: "结束日期",
  emailAddress: "电子邮件地址",
  date: "日期",
  startTime: "开始时间",
  endTime: "结束时间",
  exportBilling: "导出账单",
  month: "月份",
  year: "年份",
  dateType: "日期类型",
  createdDate: "创建日期",
  diningDate: "用餐日期",
};

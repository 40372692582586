import { localeTranslator } from "@/utils/global.util";

export const VIEW_TYPES = [
  {
    name: localeTranslator("$vuetify.allotments.fullday"),
    value: "fullday",
    isShow: true,
  },
  {
    name: localeTranslator("$vuetify.allotments.daily"),
    value: "daily",
    isShow: false,
  },
  {
    name: localeTranslator("$vuetify.allotments.monthly"),
    value: "month",
    isShow: true,
  },
];

export const REASON_LIST = [
  localeTranslator("$vuetify.allotments.privateFunction"),
  localeTranslator("$vuetify.allotments.restaurantIsClosed"),
  localeTranslator("$vuetify.allotments.LowFoodStock"),
  localeTranslator("$vuetify.allotments.fullyBooked"),
  localeTranslator("$vuetify.allotments.Others"),
];

export const COLORS_PACKAGES = {
  "All You Can Eat": "red",
  "Party Pack": "green",
  Xperience: "blue",
  "Hungry@Home": "purple",
};

export const ALLOTMENT_DAYS = [
  {
    id: "1",
    label: "Monday",
    textLabel: localeTranslator("$vuetify.allotments.monday"),
    closed: false,
    firstBookingTime: null,
    lastBookingTime: null,
    seatAvailable: null,
    subTimes: [],
  },
  {
    id: "2",
    label: "Tuesday",
    textLabel: localeTranslator("$vuetify.allotments.tuesday"),
    closed: false,
    firstBookingTime: null,
    lastBookingTime: null,
    seatAvailable: null,
    subTimes: [],
  },
  {
    id: "3",
    label: "Wednesday",
    textLabel: localeTranslator("$vuetify.allotments.wednesday"),
    closed: false,
    firstBookingTime: null,
    lastBookingTime: null,
    seatAvailable: null,
    subTimes: [],
  },
  {
    id: "4",
    label: "Thursday",
    textLabel: localeTranslator("$vuetify.allotments.thursday"),
    closed: false,
    firstBookingTime: null,
    lastBookingTime: null,
    seatAvailable: null,
    subTimes: [],
  },
  {
    id: "5",
    label: "Friday",
    textLabel: localeTranslator("$vuetify.allotments.friday"),
    closed: false,
    firstBookingTime: null,
    lastBookingTime: null,
    seatAvailable: null,
    subTimes: [],
  },
  {
    id: "6",
    label: "Saturday",
    textLabel: localeTranslator("$vuetify.allotments.saturday"),
    closed: false,
    firstBookingTime: null,
    lastBookingTime: null,
    seatAvailable: null,
    subTimes: [],
  },
  {
    id: "7",
    textLabel: localeTranslator("$vuetify.allotments.sunday"),
    label: "Sunday",
    closed: false,
    firstBookingTime: null,
    lastBookingTime: null,
    seatAvailable: null,
    subTimes: [],
  },
];

export const help = {
  help: "帮助",
  nameOfHowTo: "Name Of How To",
  loremIpsum: "Lorem ipsum is simply text of the printing and typeset.",
  support: "Support",
  supportChannels: "支持渠道",
  hhForBusiness: "@HHforbusiness",
  addLine: "添加 Line",
  supportHungryHub: "support@hungryhub.com",
  sendEmail: "发送电子邮件",
  seeMore: "See More",
  chatOnWhatsApp: "WhatsApp上聊天",
};

export const dashboard = {
  todayBooking: "今天的预订",
  upComingBooking: "未来7天的预订和配额",
  thisMonthBooking: "本月预订",
  thisMonthVoucher: "本月代金券销售",
  pendingBooking: "待定预订",
  bookings: "预订",
  covers: "覆盖率",
  seatsAvailable: "可用座位",
  revenue: "收入",
  redeemed: "已兑换",
  potentialRevenue: "潜在收入",
  manageAllotment: "管理配额",
  packagesSummary: "套餐总结 (过去30天)",
  onlinePackages: "在线套餐",
  packages: "套餐",
  bestSeller: "畅销品",
  hungryHubUnlimitedLobster: "Hungry Hub Unlimited Lobster",
  ratingSummary: "評分概覽 (過去30天)",
  rating: "评分",
  reviews: "评论",
  manageBooking: "管理预订",
  overall: "总体",
  food: "食物",
  ambience: "氛围",
  service: "服务",
  value: "价值",
  seeMore: "查看更多",
  today: "Today",
  revenueFromBookings: "预订收入",
  thisMonth: "本月",
  lastMonth: "上月",
  newsAndUpdate: "新闻与更新",
};

export default {
  login: "เข้าสู่ระบบ",
  titleLogin: "ยินดีต้อนรับสู่ Partner Portal",
  userNameField: "อีเมล",
  passwordField: "รหัสผ่าน",
  rememberMe: "จดจำฉัน",
  forgotPassword: "ลืมรหัสผ่าน",
  signin: "เข้าสู่ระบบ",
  register: "ลงทะเบียน",
  errorMessage:
    "อีเมลและรหัสผ่านที่คุณป้อนไม่ตรงกับบันทึกของเรา โปรดตรวจสอบอีกครั้งและลองอีกครั้ง",
  individualLogin: "Individual Login",
  groupLogin: "Group Login",
};

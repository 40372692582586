export default {
  layout: {
    welcome: "Welcome White Orchird Bistro, let's get you started!",
    title: "Would you like to sell Package or Voucher?",
    createPackage: "Create Package",
    createPackageText:
      "Types of packages that are only available on Hungry Hub. You will be setting up number of seatings to be opened on the system,menu and price for the package.",
    createVoucher: "Create Voucher",
    createVoucherText:
      "Voucher is a discount coupon, with privileges. Customers will book directly with our partner hotels and restaurants. You will be setting up the voucher details, quantity and price to start selling on our system.",
    getStateButton: "Get started",
  },
  logged: {
    galleryManagement: "图库管理",
    searchBuilding: "Search Building",
    pleaseChooseYourLocation: "Please choose your location",
    addMoreButton: "添加更多",
    deleteButton: "删除",
    textStep: "Step",
    textOf: "of",
    stepStoreSetting: "Store Setting",
    stepAllotmentManagement: "Allotment Management",
    stepPackage: "Package & Menu",
    stepDocument: "Document",
    supportTitle: "Question? Click help for support",
    textNoteVerify: "Please complete the steps to verify your account",
    textNoteVerifySuccess: "You will hear from us within 5-7 days",
    register: "Register",
    successVerifyTitle: "Thank you! Your account is in review!",
    buttonEditAndResubmit: "Edit info and resubmit",
    textComplete: "Complete!",
    restaurantDetailsFacilities: "餐厅详情、地点与设施",
    hotelDetailsLocationFacilities: "位置與設施",
    experienceDetailLocationFacilities: "Location & facilities",
    primary: "Primary",
    secondary: "Secondary",
    primaryCuisine: "Primary Cuisine",
    secondaryCuisine: "Secondary Cuisine",
    primaryDiningStyle: "Primary Dining Style",
    secondaryDiningStyle: "Secondary Dining Style",
    displayTag: "显示标签",
    subTag: "子标签",
    displayTagCuisine: "Display Tag Cuisine",
    subTagCuisine: "Sub Tag Cuisine",
    displayTagDiningStyle: "Display Tag Dining Style",
    subTagDiningStyle: "Sub Tag Dining Style",
    maxChoices: "最多 {0} 个选择",
    seeMore: "查看更多",
    seeLess: "收起",
    locationGmap: "Google地图中的位置",
    city: "城市",
    popularZone: "热门区域",
    district: "地区",
    shoppingMall: "购物中心",
    btsRoute: "BTS路线",
    mrtRoute: "MRT路线",
    facilities: "设施",
    restaurantInformation: "餐厅信息",
    hotelInformation: "Hotel information",
    experienceInformation: "Experience information",
    mainContactPerson: "主要联系人",
    country: "国家",
    currency: "货币",
    pleaseContact: "如果您希望更改国家，请联系支持团队",
    shortNameEng: "短名称 (EN) 用于短信",
    shortNameTha: "短名称 (TH) 用于短信",
    character: "(最多20个字符)",
    primaryStorePhone: "主要商店电话号码",
    socialMedia: "社交媒體與網站",
    secondaryStore: "次要門店電話",
    name: "姓名",
    role: "Role",
    phoneNumber: "电话号码",
    emailAddress: "电子邮件地址",
    stepcreateVoucher: "Create Voucher",
    hotelBrand: "Hotel Brand",
    starRating: "Star Rating",
    typeOfExperience: "Type of Experience",
    groupSize: "Group Size",
    typeOfVenue: "Type of Venue",
    pleaseExplainAbout: "Please explain about your food service",
    logo: "徽标",
    coverImage: "封面图片",
    addPhoto: "新增照片",
    changePhoto: "更改照片",
    dropPhoto: "将照片拖到此处上传",
    dropPhotoMessage: "*最大上传文件大小{0} MB",
    yourPicture: "您的图片",
    priority: "优先级",
    optional: "可选",
    thumbnail: "缩略图",
    gallery: "图库",
    photomanagement: "照片管理",
    cuisineAndDiningStyle: "Cuisine & Dining Style",
    cuisine: "菜系",
    diningStyle: "用餐风格",
    location: "位置",
    place: "地点",
    specifyName: `Please specify the name (s)`,
    specifyLink: `請指定帳號或鏈接`,
    onSiteRestaurant: `
    <span class="d-block"> On-site</span>
    <span class="d-block">
      Restaurants
      <span class="red--text">*</span>
    </span>`,
    availableRoom: `<span class="d-block"> Available</span>
    <span class="d-block">
      Rooms
      <span class="red--text">*</span>
    </span>`,
    averageRoomRate: `
    <span class="d-block"> Average </span>
    <span class="d-block">
      Room Rate
      <span class="red--text">*</span>
    </span>`,
  },
  form: {
    howdid: "How did you hear",
    hear: "about Hungry Hub?",
    howDidHear: "How did you hear about Hungry Hub?",
    title: "Register",
    aboutYourOutlet: "About Your Outlet",
    outletType: "Outlet Type",
    pleaseSelectOne: "Please select one",
    country: "Country",
    city: "City",
    select: "Select",
    name: "姓名",
    primaryPhoneNo: "Primary Phone No.",
    operatingHours: `<span class="d-block">营业时间</span><span class="d-block">(开放 - 关闭)
    <span class="red--text">*</span></span>`,
    LocationInGoogleMap: "Location in Google Map",
    description: "描述",
    en: "(EN)",
    th: "(TH)",
    primaryCuisine: "Primary Cuisine",
    numberOfBranch: "Number of Branch",
    es: "(es)",
    anyNearLandmark: "Any Near Landmark?",
    restaurant: "Restaurant",
    hotel: "Hotel",
    experience: "Experience",
    lorem:
      "— Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.",
    howDoWeContactYou: "How Do We Contact You?",
    yourName: "Your Name",
    yourPhoneNumber: "Your Phone Number",
    yourRole: "Your Role",
    yourEmailAddress: "Your Email Address",
    howDidYouHearAboutHungryHub: "How did you hear about Hungry Hub?",
    accountInformation: "Account Information",
    youWillUseThis: "You will use this information to log in to Partner Portal",
    emailAddress: "Email Address",
    password: "Password",
    confirmPassword: "Confirm Password",
    thankYouPleaseCheck:
      "Thank You! Please Check Your Email For Log In Information.",
    verified: "Verified!",
    youHaveSuccessfully: "You have successfully verified the account.",
    charactersLimit: "*最多5000个字符",
    tooltips: {
      restaurant:
        "select this option if you are a restaurant or a restaurant in a hotel.",
      hotel:
        "select this option if you are a hotel and wants to open the system for room booking.",
      experience: "select this option if you provide an experience.",
    },
  },
};

const localStorageSwitcherKey = "hrhubenv";

export const isDevelopment = import.meta.env.APP_NODE_ENV === "development";

export const getStorageSwitcher = () => {
  if (isDevelopment) {
    // Check if the key exists in localStorage
    if (localStorage.getItem(localStorageSwitcherKey)) {
      // Get the environment data from localStorage
      const envData = JSON.parse(localStorage.getItem(localStorageSwitcherKey));
      // Return the environment data
      return envData;
    }
    // Return null
    return null;
  } else {
    return {
      APP_BASE_API_URL: import.meta.env.APP_BASE_API_URL,
      APP_BASE_API_RESTAURANT_URL: import.meta.env.APP_BASE_API_RESTAURANT_URL,
    };
  }
};

export const setStorageSwitcher = (data) => {
  if (isDevelopment) {
    const envData = {
      ENV_KEY: data.envKey,
      APP_BASE_API_URL: data.baseApiUrl,
      APP_BASE_API_RESTAURANT_URL: data.baseApiRestaurantUrl,
    };
    // Save the environment data in localStorage
    localStorage.setItem(localStorageSwitcherKey, JSON.stringify(envData));
  }
};

export const storageSwitcher = () => {
  if (isDevelopment) {
    // Check if the key exists in localStorage
    if (!localStorage.getItem(localStorageSwitcherKey)) {
      // Create an object with the necessary environment variables
      const envData = {
        ENV_KEY: "env",
        APP_BASE_API_URL: import.meta.env.APP_BASE_API_URL,
        APP_BASE_API_RESTAURANT_URL: import.meta.env
          .APP_BASE_API_RESTAURANT_URL,
      };
      // Save the environment data in localStorage
      localStorage.setItem(localStorageSwitcherKey, JSON.stringify(envData));
    }
  } else {
    // Remove the key from localStorage
    console.log("Remove localStorage");
    localStorage.removeItem("hrhubenv");
  }
};

export const getStorageBookingPreviousQueryParam = () => {
  return localStorage.getItem("hhPreviousBookingQueryParam");
};

export const setStorageBookingPreviousQueryParam = (payload) => {
  localStorage.setItem("hhPreviousBookingQueryParam", payload);
};

export const getStorageBookingTableInfo = () => {
  return localStorage.getItem("hhPreviousBookingTableInfo");
};

export const setStorageBookingTableInfo = (payload) => {
  localStorage.setItem("hhPreviousBookingTableInfo", payload);
};

export const removeStorageBookingTableInfo = () => {
  localStorage.removeItem("hhPreviousBookingTableInfo");
};

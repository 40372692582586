export default {
  analytics: "Analytics",
  performanceReview: "Performance Review",
  ranking: "Ranking",
  todayGMV: "Today’s GMV",
  yourRankingIn: "Your Ranking In ",
  leader: "Leader",
  average: "平均",
  inPattaya: "in Pattaya",
  yourRankingInCuisine: "Your Ranking In Cuisine",
  inJapanese: "in Japanese",
  yourRankingInPackageType: "Your Ranking In Package Type",
  location: "Your ranking in Location",
  cuisine: "Your ranking in Cuisine",
  package_type: "Your ranking in Package Type",
  inAYCE: "in AYCE",
  tipsToImproveYourRanking: "Tips To Improve Your Ranking",
};

export default {
  billing: "账单",
  billingOverview: "账单概览",
  totalDueAmountExcludingTax: "应付总额（不含税）",
  totalGMV: "Total GMV",
  totalRevenue: "Total revenue",
  totalRevenueGMV: "总收入 (GMV)",
  totalPrepaymentAndCodes: "总预付款 + 代码",
  commissionBreakdown: "佣金明细",
  packages: "套餐",
  hungryHome: "饥饿@家",
  allYouCanEat: "自助餐",
  packageName: "套餐名称",
  price: "价格",
  totalCover: "总覆盖率",
  commission: "佣金",
  experience: "体验套餐",
  partyPack: "派对套餐",
  January: "一月",
  February: "二月",
  March: "三月",
  April: "四月",
  May: "五月",
  June: "六月",
  July: "七月",
  August: "八月",
  September: "九月",
  October: "十月",
  November: "十一月",
  December: "十二月",
};
